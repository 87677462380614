import { useColorMode, IconButton } from "@chakra-ui/react";
import React from "react";
import { HiMoon, HiSun } from "react-icons/hi";

const ThemeButton = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <IconButton
      aria-label="color-mode"
      icon={colorMode === "dark" ? <HiSun /> : <HiMoon />}
      onClick={toggleColorMode}
    />
  );
};

export default ThemeButton;
