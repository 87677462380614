import React, { useLayoutEffect, useState } from "react";
import {
  Container,
  Box,
  Text,
  Heading,
  Table,
  Button,
  Flex,
  Icon,
  Spinner,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import { UseApi } from "services";
import {
  HiArrowRight,
  HiCheckCircle,
  HiExclamationCircle,
  HiMail,
} from "react-icons/hi";
import { Link } from "react-router-dom";

interface Props {}

const TicketPage = (props: Props) => {
  const ticketManager = UseApi.Tickets();
  const [showIndex, setShowIndex] = useState<number | null>(null);
  useLayoutEffect(() => {
    ticketManager.fetch();
  }, []);

  function getType(_type: number) {
    switch (_type) {
      case 1:
        return "Bid";
      case 2:
        return "Bug";
      case 3:
        return "Issue";
    }
  }

  return (
    <Layout>
      <Container maxW="container.lg">
        <Heading fontWeight="extrabold" size="lg" py="5">
          Ticket List
        </Heading>
        {!ticketManager.isFullfilled && (
          <Flex alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        )}
        {ticketManager.isFullfilled && ticketManager.data?.data && (
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#ID</Th>
                <Th>User</Th>
                <Th>Type</Th>
                <Th>Message</Th>
                <Th>Processes</Th>
                <Th>Detail</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ticketManager.data.data.map((ticket: any, index: number) => (
                <Tr key={index}>
                  <Td>#{ticket.id}</Td>
                  <Td>{ticket.user.email}</Td>
                  <Td>{getType(ticket.type)}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="purple"
                      onClick={() => {
                        setShowIndex(index);
                      }}
                    >
                      Show Message
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => {
                        window.open(
                          `mailto:${ticket.user.email}?subject=${getType(
                            ticket.type
                          )} Ticket`
                        );
                      }}
                      leftIcon={<HiMail />}
                    >
                      Send Mail
                    </Button>
                  </Td>
                  <Td>
                    <Link to={`/ticket/detail/${ticket.id}`}>
                      <Button
                        size="sm"
                        colorScheme="green"
                        rightIcon={<HiArrowRight />}
                      >
                        Detail
                      </Button>
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        <Modal
          isOpen={showIndex != null}
          onClose={() => {
            setShowIndex(null);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Message</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {ticketManager.data?.data[showIndex!]?.message}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  setShowIndex(null);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </Layout>
  );
};

export default TicketPage;
