import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Container,
  Center,
  Text,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import { UseApi } from "services";
import { Link } from "react-router-dom";

interface Props {}

const PackagesPage = (props: Props) => {
  const featuresManager = UseApi.Features();
  const createManager = UseApi.FeatureCreate();
  const deleteManager = UseApi.FeatureDelete();
  const updateManager = UseApi.FeatureUpdate();
  const createDisc = useDisclosure();
  const [newTitle, setNewTitle] = useState<string>("");
  const [updateTitle, setUpdateTitle] = useState<string>("");
  const [updateFeature, setUpdateFeature] = useState<number>(-1);
  const toast = useToast();

  useLayoutEffect(() => {
    featuresManager.fetch();
  }, []);

  function handleCreate() {
    createManager.fetch(newTitle, "");
  }

  function handleUpdate() {
    updateManager.fetch(updateFeature, updateTitle);
  }

  useEffect(() => {
    if (createManager.isFullfilled) {
      toast({
        position: "top-right",
        title: "Feature added",
        status: "success",
      });
      createDisc.onClose();
      setNewTitle("");
      featuresManager.fetch();
      createManager.onReset();
    }
  }, [createManager.status]);

  useEffect(() => {
    if (updateManager.isFullfilled) {
      toast({
        position: "top-right",
        title: "Feature updated",
        status: "success",
      });
      setUpdateFeature(-1);
      setUpdateTitle("");
      featuresManager.fetch();
      updateManager.onReset();
    }
  }, [updateManager.status]);

  useEffect(() => {
    if (deleteManager.isFullfilled) {
      toast({
        position: "top-right",
        title: "Feature deleted",
        status: "warning",
      });
      featuresManager.fetch();
      deleteManager.onReset();
    }
  }, [deleteManager.status]);

  return (
    <Layout>
      <Container maxW="container.lg">
        <Flex justifyContent="space-between">
          <Heading size="lg" mb="5">
            Features Page
          </Heading>
          <Button colorScheme="blue" onClick={createDisc.onOpen}>
            Create
          </Button>
        </Flex>
        {featuresManager.isFullfilled && featuresManager.data ? (
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#ID</Th>
                <Th>Name</Th>
                <Th>Process</Th>
              </Tr>
            </Thead>
            <Tbody>
              {[...featuresManager.data.data]
                .reverse()
                .map((_feature, index) => (
                  <Tr key={index}>
                    <Td>#{_feature.id}</Td>
                    <Td>{_feature.title}</Td>
                    <Td>
                      <Flex>
                        <Button
                          size="sm"
                          colorScheme="yellow"
                          width="100px"
                          onClick={() => {
                            setUpdateTitle(_feature.title);
                            setUpdateFeature(_feature.id);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="red"
                          ml="3"
                          onClick={() => {
                            deleteManager.fetch(_feature.id);
                          }}
                        >
                          Delete
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}

        <Modal isOpen={createDisc.isOpen} onClose={createDisc.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={createDisc.onClose}>
                Close
              </Button>
              <Button colorScheme="blue" onClick={handleCreate}>
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={updateFeature !== -1}
          onClose={() => {
            setUpdateFeature(-1);
            setUpdateTitle("");
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  value={updateTitle}
                  onChange={(e) => setUpdateTitle(e.target.value)}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => {
                  setUpdateFeature(-1);
                  setUpdateTitle("");
                }}
              >
                Close
              </Button>
              <Button colorScheme="orange" onClick={handleUpdate}>
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </Layout>
  );
};

export default PackagesPage;
