import { Flex, Icon, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { HiLink } from "react-icons/hi";
import { Link as RRDLink, useLocation } from "react-router-dom";

interface Props {
  title: string;
  path: string;
}

export const Link: FC<Props> = (props) => {
  const loc = useLocation();
  return (
    <RRDLink to={props.path}>
      <Flex alignItems="center" mr="5">
        <Icon as={HiLink} />
        <Text ml="2" textDecoration={loc.pathname === props.path ? "underline" : undefined}>
          {props.title}
        </Text>
      </Flex>
    </RRDLink>
  );
};
