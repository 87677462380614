import React from 'react'

interface Props {
  
}

const Package = (props: Props) => {
  return (
    <div>
      Package
    </div>
  )
}

export default Package
