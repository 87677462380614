import React, { FC } from "react";
import { Flex, Text, Avatar, Box, Tooltip } from "@chakra-ui/react";
import Moment from 'moment'

interface Props {
  text: string;
  direction: "left" | "right";
  fullname: string;
  date: Date;
}

const Item: FC<Props> = (props) => {
  return (
    <Flex
      w="100%"
      justifyContent={props.direction === "left" ? "flex-start" : "flex-end"}
      marginBottom="3"
      alignItems="flex-start"
    >
      {props.direction === "left" && (
        <Tooltip label={props.fullname}>
          <Avatar name={props.fullname} mr="2" />
        </Tooltip>
      )}
      <Box maxW="80%">
        <Box
          rounded="md"
          bgColor={props.direction === "left" ? "blue.300" : "gray.400"}
          p="3"
        >
          <Text fontSize="sm">{props.text}</Text>
        </Box>
        <Text textAlign={props.direction} w="100%" fontSize="xs" mt="1">
          {Moment(props.date).format("HH:mm DD/MM/YY")}
        </Text>
      </Box>
      {props.direction === "right" && (
        <Tooltip label={props.fullname}>
          <Avatar name={props.fullname} ml="2" />
        </Tooltip>
      )}
    </Flex>
  );
};

export default Item;
