import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HTMLChakraProps,
  Input,
  Stack,
} from "@chakra-ui/react";
import * as React from "react";
import { UseApi } from "services";
import { PasswordField } from "./PasswordField";
import { Authenticator } from "helper/Authenticator";

export const LoginForm = (props: HTMLChakraProps<"form">) => {
  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
  });
  const loginManager = UseApi.Login();

  React.useEffect(() => {
    if (loginManager.isFullfilled) {
      Authenticator.Login(loginManager.data?.data.token!);
    }
  }, [loginManager.status]);

  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault();
        // your login logic here
        loginManager.fetch(credentials.email, credentials.password);
      }}
      {...props}
    >
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            name="email"
            type="email"
            autoComplete="email"
            required
            onChange={(e) =>
              setCredentials((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            value={credentials.email}
          />
        </FormControl>
        <PasswordField
          onChange={(e) =>
            setCredentials((prev) => ({
              ...prev,
              password: e.target.value,
            }))
          }
          value={credentials.password}
        />
        <Button type="submit" colorScheme="blue" size="lg" fontSize="md">
          Sign in
        </Button>
      </Stack>
    </chakra.form>
  );
};
