import {
  Box, Flex,
  Heading, Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import React, { useLayoutEffect } from "react";
import { UseApi } from "services";
import FeatureActions from "./FeatureActions";

interface Props {}

const Feature = (props: Props) => {
  const featuresManager = UseApi.Features();
  useLayoutEffect(() => {
    featuresManager.fetch();
  }, []);

  return (
    <Box>
      <FeatureActions  />
      {!featuresManager.isFullfilled && (
        <Flex alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {featuresManager.isFullfilled && featuresManager.data?.data && (
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>#ID</Th>
            </Tr>
          </Thead>
          <Tbody>
            {featuresManager.data.data.map((feature, index) => (
              <Tr>
                <Td>#{feature.id}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default Feature;
