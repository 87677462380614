import React, { FC, useState } from "react";
import { Box, Text, Textarea, IconButton, Flex } from "@chakra-ui/react";
import { HiArrowRight } from "react-icons/hi";

interface Props {
  onSend: (text: string) => void;
  isLoading: boolean;
}

const SendForm: FC<Props> = (props) => {
  const [message, setMessage] = useState<string>("");
  return (
    <Flex minH="100px" p="2" bgColor="blackAlpha.200" alignItems="flex-start">
      <Textarea
        placeholder="Enter your message"
        resize="none"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <IconButton
        aria-label="send"
        ml="3"
        disabled={props.isLoading}
        isLoading={props.isLoading}
        onClick={() => {
          props.onSend(message);
          setMessage("");
        }}
        colorScheme="blue"
        icon={<HiArrowRight />}
      />
    </Flex>
  );
};

export default SendForm;
