import { axiosInstance } from "./interceptor";
const COLLECTION_URL = "/package";
const PACKAGES_URL = COLLECTION_URL + "/packages";
const FEATURES_URL = COLLECTION_URL + "/features";

async function apiPackages() {
  return await axiosInstance().get(PACKAGES_URL);
}

async function apiFeatures() {
  return await axiosInstance().get(FEATURES_URL);
}

async function apiFeatureCreate(title: string, dec: string) {
  return await axiosInstance().post(FEATURES_URL, { title, dec });
}

async function apiFeatureDelete(id: number) {
  return await axiosInstance().delete(FEATURES_URL + `/${id}`);
}

async function apiFeatureUpdate(id: number, title: string) {
  return await axiosInstance().put(FEATURES_URL + `/${id}`, { title });
}

async function apiPackageCreate(data: PackageRequestModel) {
  return await axiosInstance().post(PACKAGES_URL, data);
}

async function apiPackageDelete(id: number) {
  return await axiosInstance().delete(PACKAGES_URL + `/${id}`);
}
async function apiPackageDetail(id: number) {
  return await axiosInstance().get(PACKAGES_URL + `/${id}`);
}
async function apiPackageUpdate(id: number, data: any) {
  return await axiosInstance().put(PACKAGES_URL + `/${id}`, data);
}


export default {
  apiPackages,
  apiFeatures,
  apiPackageCreate,
  apiFeatureCreate,
  apiFeatureDelete,
  apiFeatureUpdate,
  apiPackageDelete,
  apiPackageDetail,
  apiPackageUpdate
};

export interface PackagesDTO {
  id: number;
  title: string;
  description: string;
  month_price: number;
  year_price: number;
  createdAt: string;
  updatedAt: string;
  status: number;
  features: Feature[];
}

export interface Feature {
  id: number;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: number;
}

interface PackageRequestModel {
  title: string;
  description: string;
  monthPrice: string;
  yearPrice: string;
  featuresIds: number[];
}
