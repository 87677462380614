import React, { useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { UseApi } from "services";
import Message from "components/molecules/Message";
import Layout from "components/templates/Layout";
import { Container, Center, Spinner } from "@chakra-ui/react";

interface Props {}

const TicketDetailPage = (props: Props) => {
  const { id } = useParams<{ id: string }>();

  const ticketManager = UseApi.TicketDetail();
  const createManager = UseApi.SendMessage();

  useLayoutEffect(() => {
    if (!isNaN(parseInt(id))) {
      ticketManager.fetch(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createManager.isFullfilled) {
      createManager.onReset();
      ticketManager.fetch(parseInt(id));
    }
  }, [createManager.status]);

  return (
    <Layout>
      <Container maxW="container.lg">
        {!ticketManager.data && (
          <Center>
            <Spinner />
          </Center>
        )}
        {!!ticketManager.data && (
          <Message.Container>
            <Message.ItemBox>
              {ticketManager.data?.data?.messages.map(
                (message: any, index: number) => (
                  <Message.Item
                    key={index}
                    text={message.text}
                    fullname={message.user.fullname}
                    direction={!message.moderator ? "left" : "right"}
                    date={message.createdAt}
                  />
                )
              )}
            </Message.ItemBox>
            <Message.SendForm
              isLoading={createManager.isPending}
              onSend={(text) => {
                createManager.fetch(parseInt(id), text);
              }}
            />
          </Message.Container>
        )}
      </Container>
    </Layout>
  );
};

export default TicketDetailPage;
