import { Button, Container, Flex, Heading, Icon, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import React, { useLayoutEffect } from "react";
import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi';
import { UseApi } from "services";
import * as homeLogics from './user-repository';

const User = () => {
  const userListManager = UseApi.UserList();

  useLayoutEffect(() => {
    userListManager.fetch();
  }, []);

  return (
    <Layout>
      <Container maxW="container.lg" mt="5">
      <Heading fontWeight="extrabold" size="lg" py="5">User List</Heading>

        {!userListManager.isFullfilled && (
          <Flex alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        )}
        {userListManager.isFullfilled && userListManager.data?.data && (
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#ID</Th>
                <Th>Fullname</Th>
                <Th>Email</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Is Verified</Th>
                <Th>Processes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userListManager.data.data.map((user, index) => (
                <Tr>
                  <Td>#{user.id}</Td>
                  <Td>{user.fullname}</Td>
                  <Td>{user.email}</Td>
                  <Td>{homeLogics.getUserType(user.userType)}</Td>
                  <Td>{homeLogics.getUserStatus(user.status)}</Td>
                  <Td textAlign="center"><Icon color={user.isVerified ? "green.500" : "orange.400"} as={user.isVerified ? HiCheckCircle : HiExclamationCircle} /></Td>
                  <Td><Button colorScheme="red" size="sm" disabled>Ban</Button></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Container>
    </Layout>
  );
};

export default User;
