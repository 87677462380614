import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import ThemeButton from "components/atoms/ThemeButton";
import { useRedux } from "hooks/redux-hooks";
import { Link } from "./Link";
import { Authenticator } from "helper/Authenticator";

const Header = () => {
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const [, dp] = useRedux();
  return (
    <Box bgColor={bgColor} boxShadow="base" mb="10">
      <Container maxW="container.lg">
        <Flex justifyContent="space-between" rounded="lg" py="6">
          <Flex alignItems="center">
            <Heading size="lg" color="blue.500">
              NoxGuard
            </Heading>
            <Flex ml="8">
              <Link path="/" title="Home" />
              <Link path="/user" title="User" />
              {/* <Link path="/pricing" title="Pricing" /> */}
              <Link path="/ticket" title="Ticket" />
              <Link path="/package" title="Packages" />
              <Link path="/feature" title="Features" />
              <Link path="/blog" title="Blogs" />
            </Flex>
          </Flex>
          <Flex>
            <Button
              variant="ghost"
              mr="3"
              colorScheme="red"
              onClick={Authenticator.Logout}
            >
              Logout
            </Button>
            <ThemeButton />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
