import { Container, Heading } from "@chakra-ui/layout";
import Layout from "components/templates/Layout";
import React from "react";

interface Props {}

const Home = (props: Props) => {
  return (
    <Layout>
      <Container maxW="container.lg">
        <Heading>Home</Heading>
      </Container>
    </Layout>
  );
};

export default Home;
