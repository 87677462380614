import axios, { AxiosError, AxiosInstance } from "axios";
import { store } from "../store";
import { useFetchManager, useFetchManagerStore } from "../hooks/fetch-manager";
import { clearAuth } from "store/auth/actions";

export const axiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create();
  // Add a request interceptor
  axiosInstance.interceptors.request.use((config) => {
    return new Promise((resolve) => {
      // config.baseURL = "http://172.20.10.7:5050";
      // config.baseURL = "http://localhost:5050";
      config.baseURL = "https://api.noxguard.ac";
      config.headers.Authorization = "Bearer " + store.getState().auth.jwt;
      resolve(config);
    });
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      console.log("Interceptors Error", error);
      if (error.response?.data?.error.name == "TokenExpiredError") {
        document.cookie = `user=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        store.dispatch(clearAuth());
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
