import { axiosInstance } from "./interceptor";
const COLLECTION_URL = "/ticket";
const LIST_URL = COLLECTION_URL + "/";
const DETAIL_URL = COLLECTION_URL + "/detail";
const CREATE_MESSAGE_MODERATOR_URL = COLLECTION_URL + "/message-moderator";

async function apiTickets() {
  return await axiosInstance().get(LIST_URL);
}

async function apiTicketDetail(ticketId: number) {
  return await axiosInstance().get(`${DETAIL_URL}/${ticketId}`);
}

async function apiCreateMessage(ticketId: number, text: string) {
  return await axiosInstance().post(
    `${CREATE_MESSAGE_MODERATOR_URL}/${ticketId}`,
    { text }
  );
}

export default { apiTickets, apiTicketDetail, apiCreateMessage };
