import React from 'react'
import { Badge } from "@chakra-ui/react";

export enum UserStatus {
  active = 1,
  passive = 2,
  deleted = 3,
  banned = 4,
  frozen = 5,
  closed = 6,
}

export enum UserType {
  costumer = 1,
  editor = 2,
  manager = 3,
  admin = 4,
  superadmin = 5,
}

export function getUserType(userType: UserType) {
  switch (userType) {
    case UserType.admin:
      return <Badge colorScheme="pink">Admin</Badge>;
    case UserType.superadmin:
      return <Badge colorScheme="purple">SuperAdmin</Badge>;
    case UserType.superadmin:
      return <Badge colorScheme="purple">SuperAdmin</Badge>;
    default:
      return <Badge colorScheme="blue">Member</Badge>;
  }
}

export function getUserStatus(userStatus: UserStatus) {
  switch (userStatus) {
    default:
      return <Badge colorScheme="green">Active</Badge>;
  }
}