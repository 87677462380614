import { Flex } from "@chakra-ui/react";
import React, { FC, useEffect, useRef } from "react";

const ItemBox: FC = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: ref.current.scrollHeight });
    }
  });

  return (
    <Flex
      ref={ref}
      alignItems="stretch"
      w="100%"
      flexDir="column"
      backgroundColor="blackAlpha.100"
      p="3"
      flex={1}
      overflowY="scroll"
    >
      {props.children}
    </Flex>
  );
};

export default ItemBox;
