import { Box } from '@chakra-ui/react'
import Header from 'components/organisms/Header'
import React, {FC} from 'react'

interface Props {
  
}

const Layout: FC<Props> = (props) => {
  return (
    <Box>
      <Header />
      {props.children}
    </Box>
  )
}

export default Layout