import React, { useEffect, useLayoutEffect } from "react";
import {
  Container,
  Center,
  Text,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import { UseApi } from "services";
import { Link } from "react-router-dom";

interface Props {}

const PackagesPage = (props: Props) => {
  const packagesManager = UseApi.Packages();
  const deleteManager = UseApi.PackageDelete();
  const toast = useToast();

  useLayoutEffect(() => {
    packagesManager.fetch();
  }, []);

  function handleDelete(id: number) {
    deleteManager.fetch(id);
  }

  useEffect(() => {
    if (deleteManager.isFullfilled) {
      toast({
        position: "top-right",
        title: "Package deleted",
        status: "warning",
      });
      packagesManager.fetch();
      deleteManager.onReset();
    }
  }, [deleteManager.status]);

  return (
    <Layout>
      <Container maxW="container.lg">
        <Flex justifyContent="space-between">
          <Heading size="lg" mb="5">
            Packages Page
          </Heading>
          <Link to="/package/create">
            <Button colorScheme="blue">Create</Button>
          </Link>
        </Flex>
        {packagesManager.isFullfilled && packagesManager.data ? (
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>#ID</Th>
                <Th>Name</Th>
                <Th>Month Price</Th>
                <Th>Year Price</Th>
                <Th>Is Contact Required</Th>
                <Th>Features</Th>
                <Th>Process</Th>
              </Tr>
            </Thead>
            <Tbody>
              {packagesManager.data.data.map((_package, index) => (
                <Tr key={index}>
                  <Td>#{_package.id}</Td>
                  <Td>{_package.title}</Td>
                  <Td>{_package.month_price}</Td>
                  <Td>{_package.year_price}</Td>
                  <Td>Required</Td>
                  <Td fontSize="xs">
                    {_package.features
                      .map((feature) => feature.title)
                      .join(", ")}
                  </Td>
                  <Td>
                    <Flex flexDir="column">
                      <Link to={"/package/update/" + _package.id}>
                        <Button
                          colorScheme="yellow"
                          size="sm"
                          w="100px"
                          mb="2"
                          h="50px"
                        >
                          Edit
                        </Button>
                      </Link>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => {
                          handleDelete(_package.id);
                        }}
                      >
                        Delete
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </Container>
    </Layout>
  );
};

export default PackagesPage;
