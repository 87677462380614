import { Container, Flex, Heading, Button } from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import React, { useState } from "react";
import Feature from "./Feature";
import Package from "./Package";

interface Props {}

const Pricing = (props: Props) => {
  const [pageMode, setPageMode] = useState<"package" | "feature">("feature");
  return (
    <Layout>
      <Container maxW="container.lg">
        <Flex mb="10">
          <Button
            onClick={() => setPageMode("feature")}
            flex={1}
            mr="2"
            colorScheme={pageMode === "feature" ? "blue" : "gray"}
          >
            Feature
          </Button>
          <Button
            onClick={() => setPageMode("package")}
            flex={1}
            ml="2"
            colorScheme={pageMode === "package" ? "blue" : "gray"}
          >
            Package
          </Button>
        </Flex>

        {pageMode === "feature" ? <Feature /> : <Package />}
      
      </Container>
    </Layout>
  );
};

export default Pricing;
