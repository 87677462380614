import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import React, { useEffect, useLayoutEffect, useState } from "react";
import RSelect from "react-select";
import { UseApi } from "services";
import { useHistory } from "react-router-dom";

interface Props {}

interface PackageState {
  packageName: string;
  features: [];
  monthPrice: string;
  yearPrice: string;
  isNeedContact: boolean;
}

const PackageCreate = (props: Props) => {
  const history = useHistory();
  const featuresManager = UseApi.Features();
  const packageCreateManager = UseApi.PackageCreate();
  const [state, setState] = useState<PackageState>({
    features: [],
    isNeedContact: false,
    monthPrice: "",
    packageName: "",
    yearPrice: "",
  });

  function setKey(_key: keyof PackageState, value: any) {
    setState((prev) => ({ ...prev, [_key]: value } as any));
  }

  function handleClickCreate() {
    packageCreateManager.fetch({
      description: "",
      featuresIds: state.features,
      monthPrice: state.monthPrice,
      yearPrice: state.yearPrice,
      title: state.packageName,
    });
  }

  useEffect(() => {
    if (packageCreateManager.isFullfilled) {
      history.push("/package");
    }
  }, [packageCreateManager.status]);

  useLayoutEffect(() => {
    featuresManager.fetch();
  }, []);

  return (
    <Layout>
      <Container maxW="container.lg">
        <Heading size="lg">Package Create</Heading>
        <SimpleGrid columns={[1, 1, 2]} spacing="6">
          <Box maxW="500px" mt="5">
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                value={state.packageName}
                onChange={(e) => setKey("packageName", e.target.value)}
              />
            </FormControl>
            <FormControl id="features" mt="3">
              <FormLabel>Features</FormLabel>
              <RSelect
                isMulti
                onChange={(val) =>
                  setKey(
                    "features",
                    val.map((item) => item.value)
                  )
                }
                options={
                  (featuresManager.isFullfilled &&
                    !!featuresManager.data &&
                    featuresManager.data.data.map(
                      (item, index) =>
                        ({
                          label: item.title,
                          value: item.id,
                        } || [])
                    )) ||
                  []
                }
              />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Month Price</FormLabel>
              <Input
                type="number"
                value={state.monthPrice}
                onChange={(e) => setKey("monthPrice", parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Year Price</FormLabel>
              <Input
                type="number"
                value={state.yearPrice}
                onChange={(e) => setKey("yearPrice", parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Is Contact Required</FormLabel>
              <Checkbox
                type="checkbox"
                checked={state.isNeedContact}
                onChange={(e) => setKey("isNeedContact", !state.isNeedContact)}
              />
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button
                colorScheme="blue"
                onClick={handleClickCreate}
                isLoading={packageCreateManager.isPending}
                disabled={
                  !state.packageName ||
                  !state.monthPrice ||
                  !state.yearPrice ||
                  state.features?.length === 0
                }
              >
                Create
              </Button>
            </Flex>
          </Box>
        </SimpleGrid>
      </Container>
    </Layout>
  );
};

export default PackageCreate;
