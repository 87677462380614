import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Flex,
  Image,
} from "@chakra-ui/react";
import ThemeButton from "components/atoms/ThemeButton";
import * as React from "react";
import { FaFacebook, FaGithub, FaGoogle } from "react-icons/fa";
import { Card } from "./Card";
import { DividerWithText } from "./DividerWithText";
import { Link } from "./Link";
import { LoginForm } from "./LoginForm";
import { Logo } from "./Logo";

const Login = () => (
  <Box
    bg={useColorModeValue("gray.50", "inherit")}
    minH="100vh"
    py="12"
    px={{ base: "4", lg: "8" }}
  >
    <Box maxW="md" mx="auto">
      <Flex justifyContent="space-between" alignItems="center" mb="14">
        <Heading color="blue.500">NoxGuard</Heading>
        <ThemeButton />
      </Flex>
      <Heading textAlign="center" size="xl" fontWeight="extrabold">
        Sign in to your account
      </Heading>
      <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
        <Text as="span">Nox Guard Admin Panel</Text>
      </Text>
      <Card>
        <LoginForm />
        <DividerWithText mt="6">Follow Us</DividerWithText>
        <SimpleGrid mt="6" columns={3} spacing="3">
          <Button color="currentColor" variant="outline">
            <VisuallyHidden>Login with Facebook</VisuallyHidden>
            <FaFacebook />
          </Button>
          <Button color="currentColor" variant="outline">
            <VisuallyHidden>Login with Google</VisuallyHidden>
            <FaGoogle />
          </Button>
          <Button color="currentColor" variant="outline">
            <VisuallyHidden>Login with Github</VisuallyHidden>
            <FaGithub />
          </Button>
        </SimpleGrid>
      </Card>
    </Box>
  </Box>
);

export default Login;
