import React from "react";
import Layout from "components/templates/Layout";
import { Container, Flex, Button, Heading, Box, Text } from "@chakra-ui/react";
import "draft-js/dist/Draft.css";
import { Editor, EditorState } from "draft-js";
interface Props {}

const BlogCreate = (props: Props) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  return (
    <Layout>
      <Container maxW="container.lg">
        <Flex justifyContent="space-between" alignItems="center">
          Blog Create
        </Flex>

        <Box bgColor="gray.100" h="300px" rounded="md" position="relative" overflowY="scroll">
          {JSON.stringify(editorState.getCurrentContent())}
          <Editor editorState={editorState} onChange={setEditorState}  />
        </Box>
      </Container>
    </Layout>
  );
};

export default BlogCreate;
