import UserAPI, { LoginDTO, RegisterDTO, UserDTO } from "./User";
import PackageAPI, { PackagesDTO, Feature } from "./Package";
import TicketAPI from "./Ticket";
import BlogAPI from "./Blog";
import { useFetchManager } from "../hooks/fetch-manager";

export const UseApi = {
  Login: () =>
    useFetchManager<typeof UserAPI.apiLogin, LoginDTO>(UserAPI.apiLogin),
  Register: () =>
    useFetchManager<typeof UserAPI.apiRegister, RegisterDTO>(
      UserAPI.apiRegister
    ),
  Me: () => useFetchManager<typeof UserAPI.apiMe, any>(UserAPI.apiMe),
  Packages: () =>
    useFetchManager<typeof PackageAPI.apiPackages, PackagesDTO[]>(
      PackageAPI.apiPackages
    ),
  PackageCreate: () =>
    useFetchManager<typeof PackageAPI.apiPackageCreate, PackagesDTO>(
      PackageAPI.apiPackageCreate
    ),
  PackageDelete: () =>
    useFetchManager<typeof PackageAPI.apiPackageDelete>(
      PackageAPI.apiPackageDelete
    ),
  PackageDetail: () =>
    useFetchManager<typeof PackageAPI.apiPackageDetail>(
      PackageAPI.apiPackageDetail
    ),
  PackageUpdate: () =>
    useFetchManager<typeof PackageAPI.apiPackageUpdate>(
      PackageAPI.apiPackageUpdate
    ),
  Features: () =>
    useFetchManager<typeof PackageAPI.apiFeatures, Feature[]>(
      PackageAPI.apiFeatures
    ),
  FeatureCreate: () =>
    useFetchManager<typeof PackageAPI.apiFeatureCreate>(
      PackageAPI.apiFeatureCreate
    ),
  FeatureDelete: () =>
    useFetchManager<typeof PackageAPI.apiFeatureDelete>(
      PackageAPI.apiFeatureDelete
    ),
  FeatureUpdate: () =>
    useFetchManager<typeof PackageAPI.apiFeatureUpdate>(
      PackageAPI.apiFeatureUpdate
    ),
  UserList: () =>
    useFetchManager<typeof PackageAPI.apiPackages, UserDTO[]>(
      UserAPI.apiUserList
    ),
  Tickets: () =>
    useFetchManager<typeof TicketAPI.apiTickets>(TicketAPI.apiTickets),
  TicketDetail: () =>
    useFetchManager<typeof TicketAPI.apiTicketDetail>(
      TicketAPI.apiTicketDetail
    ),
  SendMessage: () =>
    useFetchManager<typeof TicketAPI.apiCreateMessage>(
      TicketAPI.apiCreateMessage
    ),
  Blogs: () => useFetchManager<typeof BlogAPI.apiBlogs>(BlogAPI.apiBlogs),
};
