import { AxiosResponse } from "axios";
import { axiosInstance } from "./interceptor";
const COLLECTION_URL = "/user";
const LOGIN_URL = COLLECTION_URL + "/login";
const REGISTER_URL = COLLECTION_URL + "/register";
const ME_URL = COLLECTION_URL + "/me";
const USER_LIST_URL = COLLECTION_URL + "/user-list";

export interface LoginDTO {
  token: string;
}
async function apiLogin(email: string, password: string) {
  return await axiosInstance().post(LOGIN_URL, { email, password });
}

export interface MeDTO {}
async function apiMe() {
  return await axiosInstance().get(ME_URL);
}

export interface RegisterDTO {
  status: boolean;
}
async function apiRegister(email: string, fullname: string, password: string) {
  return await axiosInstance().post(REGISTER_URL, {email, fullname, password});
}

export interface UserDTO {
  id: number;
  email: string;
  fullname: string;
  status: number;
  isVerified: boolean;
  avatar?: any;
  userType: number;
  createdAt: string;
  updatedAt: string;
}

async function apiUserList() {
  return await axiosInstance().get(USER_LIST_URL);
}

export default {
  apiLogin,
  apiMe,
  apiRegister,
  apiUserList
};
