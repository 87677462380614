import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Layout from "components/templates/Layout";
import React, { useEffect, useLayoutEffect, useState } from "react";
import RSelect from "react-select";
import { UseApi } from "services";
import { useHistory, useParams } from "react-router-dom";

interface Props {}

interface PackageState {
  packageName: string;
  features: any[];
  monthPrice: string;
  yearPrice: string;
  isNeedContact: boolean;
}

const PackageUpdatePage = (props: Props) => {
  const history = useHistory();
  const params = useParams();
  const featuresManager = UseApi.Features();
  const packageCreateManager = UseApi.PackageUpdate();
  const packageManager = UseApi.PackageDetail();
  const [state, setState] = useState<PackageState>({
    features: [],
    isNeedContact: false,
    monthPrice: "",
    packageName: "",
    yearPrice: "",
  });

  function setKey(_key: keyof PackageState, value: any) {
    setState((prev) => ({ ...prev, [_key]: value } as any));
  }

  function handleClickCreate() {
    packageCreateManager.fetch((params as any).id, {
      description: "",
      featuresIds: state.features.map(feature => feature.value),
      monthPrice: state.monthPrice,
      yearPrice: state.yearPrice,
      title: state.packageName,
      needContact: state.isNeedContact,
    });
  }

  useEffect(() => {
    if (packageCreateManager.isFullfilled) {
      history.push("/package");
    }
  }, [packageCreateManager.status]);

  useLayoutEffect(() => {
    featuresManager.fetch();
    packageManager.fetch((params as any).id);
  }, []);

  useEffect(() => {
    if (packageManager.isFullfilled) {
      setState((prev) => ({
        ...prev,
        packageName: packageManager.data?.data.title as string,
        monthPrice: packageManager.data?.data.month_price,
        yearPrice: packageManager.data?.data.year_price,
        isNeedContact: packageManager.data?.data.needContact,
        
      }));
    }
  }, [packageManager.status]);

  useEffect(() => {
    if (packageManager.isFullfilled && featuresManager.isFullfilled) {
      setState((prev) => ({
        ...prev,
        features: packageManager.data?.data.features.map((feature: any) => ({
          value: feature.id,
          label: feature.title,
        })),
      }));
    }
  }, [packageManager.status, featuresManager.status]);

  return (
    <Layout>
      {!packageManager.isFullfilled ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Container maxW="container.lg">
          <Heading size="lg">Package Update</Heading>
          <SimpleGrid columns={[1, 1, 2]} spacing="6">
            <Box maxW="500px" mt="5">
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  value={state.packageName}
                  onChange={(e) => setKey("packageName", e.target.value)}
                />
              </FormControl>
              <FormControl id="features" mt="3">
                <FormLabel>Features</FormLabel>
                <RSelect
                  isMulti
                  value={state.features}
                  onChange={(val) => setKey("features", val)}
                  options={
                    (featuresManager.isFullfilled &&
                      !!featuresManager.data &&
                      featuresManager.data.data.map(
                        (item, index) =>
                          ({
                            label: item.title,
                            value: item.id,
                          } || [])
                      )) ||
                    []
                  }
                />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Month Price</FormLabel>
                <Input
                  type="number"
                  value={state.monthPrice}
                  onChange={(e) =>
                    setKey("monthPrice", parseInt(e.target.value))
                  }
                />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Year Price</FormLabel>
                <Input
                  type="number"
                  value={state.yearPrice}
                  onChange={(e) =>
                    setKey("yearPrice", parseInt(e.target.value))
                  }
                />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Is Contact Required</FormLabel>
                <Checkbox
                  type="checkbox"
                  defaultChecked={state.isNeedContact}
                  onChange={(e) =>
                    setKey("isNeedContact", !state.isNeedContact)
                  }
                />
              </FormControl>
              <Flex justifyContent="flex-end">
                <Button
                  colorScheme="blue"
                  onClick={handleClickCreate}
                  isLoading={packageCreateManager.isPending}
                  disabled={
                    !state.packageName ||
                    !state.monthPrice ||
                    !state.yearPrice ||
                    state.features?.length === 0
                  }
                >
                  Update
                </Button>
              </Flex>
            </Box>
          </SimpleGrid>
        </Container>
      )}
    </Layout>
  );
};

export default PackageUpdatePage;
