import MessageContainer from "./Container";
import Item from "./Item";
import ItemBox from "./ItemBox";
import SendForm from "./SendForm";

export default {
  Container: MessageContainer,
  Item: Item,
  ItemBox,
  SendForm
};
