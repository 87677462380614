import React, { FC } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

const Container: FC = (props) => {
  return (
    <Flex
      alignItems="stretch"
      w="100%"
      flexDir="column"
      minH="calc(100vh - 200px)"
      maxH="calc(100vh - 200px)"
      justifyContent="space-between"
    >
      {props.children}
    </Flex>
  );
};

export default Container;
