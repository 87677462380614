import { store } from "store";
import {
  clearAuth,
  setAuth,
  setAuthWithProject,
  setProject,
} from "store/auth/actions";
import {clearAllData} from 'store/fetch/actions'
import JwtDecode from 'jwt-decode'

const __dev__ = process.env.NODE_ENV === "development";

const LOCAL_STORAGE_KEY = "nox"

export const Authenticator = {
  Login: (token: string) => {
    const decoded: any = JwtDecode(token);
    localStorage.setItem(LOCAL_STORAGE_KEY, token);
    store.dispatch(setAuth({decoded: decoded, jwt: token, user: decoded?.userInfo}));
  },
  Logout: () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    store.dispatch(clearAuth());
  },
  Compose: () => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY);
    if(!token) return Authenticator.Logout();
    
    Authenticator.Login(token);
  }
 
};
