import React from "react";
import Layout from "components/templates/Layout";
import { Container, Flex, Button, Heading } from "@chakra-ui/react";

interface Props {}

const BlogPage = (props: Props) => {
  return (
    <Layout>
      <Container maxW="container.lg">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="lg">Blogs</Heading>
          <Button size="sm" colorScheme="purple">Create Blog</Button>
        </Flex>
      </Container>
    </Layout>
  );
};

export default BlogPage;
