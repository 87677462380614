import { Authenticator } from "helper/Authenticator";
import { useAuth } from "hooks/redux-hooks";
import React, { useLayoutEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import BlogPage from "screens/Blog";
import BlogCreate from "screens/Blog/Create";
import Home from "screens/Home";
import Login from "screens/Login";
import Packages from "screens/Packages";
import PackageCreate from "screens/Packages/Create";
import Pricing from "screens/Pricing";
import Feature from "screens/Features";
import Ticket from "screens/Ticket";
import TicketDetail from "screens/Ticket/Detail";
import User from "screens/User";
import PackageUpdatePage from "screens/Packages/Update";

const App = () => {
  const { isLoggedIn } = useAuth();

  useLayoutEffect(() => {
    Authenticator.Compose();
  }, []);

  return (
    <BrowserRouter>
      {isLoggedIn === "loginIn" ? (
        <Switch>
          <Route component={Home} path="/" exact={true} />
          <Route component={User} path="/user" exact={true} />
          <Route component={Pricing} path="/pricing" exact={true} />
          <Route component={Ticket} path="/ticket" exact={true} />
          <Route component={TicketDetail} path="/ticket/detail/:id" exact={true} />
          <Route component={Packages} path="/package" exact={true} />
          <Route component={Feature} path="/feature" exact={true} />
          <Route
            component={PackageCreate}
            path="/package/create"
            exact={true}
          />
          <Route
            component={PackageUpdatePage}
            path="/package/update/:id"
            exact={true}
          />
          <Route component={BlogPage} path="/blog" exact={true} />
          <Route component={BlogCreate} path="/blog/create" exact={true} />
        </Switch>
      ) : (
        <Switch>
          <Route component={Login} path="/" exact={false} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
