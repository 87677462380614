import {
  Flex,
  Button,
  Heading,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { FC, Fragment } from "react";

interface Props {}

const FeatureActions: FC<Props> = (props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Fragment>
      <Flex justifyContent="space-between" alignItems="center" p="3">
        <Heading size="lg">Features</Heading>
        <Button size="sm" onClick={onOpen}>Add Feature</Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Feature</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Add Feature</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default FeatureActions;
